import React, { useState } from "react"
import { navigate } from "gatsby"
import Container from "react-bootstrap/Container"
import ATTVID from "../video/att-ray-of-light.mp4"

export default function Video() {
  const[ended, setEnded] = useState(false);
  const handleEnd = () => {
    setEnded(ended);
    setTimeout(() => {
      navigate("/home")
    }, 1000)
    }
  

  return (
    <Container className="video-play">
    <video autoPlay={true} muted={true} style={{position: `absolute`, right: `0`, top: `0`, width: `100%`, height: `100%`, zIndex: `100`, objectFit: `cover`}} onEnded={handleEnd}>
      <source src={ATTVID} type="video/mp4" />
      Your browser doesn't support this video format
    </video>
   </Container>
  )
}