import React, { useState, useEffect } from "react"
import { navigate } from "gatsby-link"
import SeO from "../components/SEO"
import Video from "../components/video"


const IndexPage = () => {

const [width, setWidth] = useState()
const breakpoint = 576

  useEffect(() => {
    setWidth(window.innerWidth)
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])



  return (
    <>
      <SeO title="Welcome" />
      {width < breakpoint ?
      navigate("/home")
      : 
      <Video />
      }
    </>
  )
}

export default IndexPage

